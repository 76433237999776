// components
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// styles
import styles from "./PageHeader.module.scss";
import { SearchBar } from "components/Navigation/SearchBar";
import { carrierGroupButtons } from "components/Navigation/SearchBar/buttonsForGroup";
import { LegacyRef, useState, ReactNode } from "react";

interface PageHeaderProps {
  onSearch?: (value: string, searchByValue: string) => void;
  filter?: ReactNode;
  sorting?: ReactNode;
  headers?: ReactNode;
  searchTitle?: string;
  autoTypeOfInputSearch?: boolean;
  carrierSearch?: boolean;
  inputRef?: LegacyRef<HTMLInputElement> | null | undefined;
  checkedButtons?: string;
}

interface CarrierGroupButtonProps {
  id: number;
  labelName: string;
  checked: boolean;
  fieldName: string;
}

const PageHeader = ({
  onSearch,
  filter,
  sorting,
  headers,
  searchTitle = "Search...",
  autoTypeOfInputSearch = true,
  inputRef = undefined,
  checkedButtons = undefined,
}: PageHeaderProps) => {
  const [checkedIDs] = useState<number[]>(() => {
    if (!checkedButtons) return [1];
    const fieldNamesArray = checkedButtons.split(",");

    return carrierGroupButtons
      .filter((button) => fieldNamesArray.includes(button.fieldName))
      .map((button) => button.id);
  });

  const getCheckedButtonNamesString = (
    checkedIDs: number[],
    carrierGroupButtons: CarrierGroupButtonProps[]
  ) => {
    const checkedButtonNames = checkedIDs
      .map((id) => {
        const button = carrierGroupButtons.find(
          (button: CarrierGroupButtonProps) => button.id === id
        );
        return button ? button.fieldName : null;
      })
      .filter(Boolean);

    return checkedButtonNames.join(",");
  };

  const searchByField = getCheckedButtonNamesString(
    checkedIDs,
    carrierGroupButtons
  );

  return (
    <div className={styles.header}>
      <div className={styles.pageHeader}>
        <Breadcrumbs />
        {onSearch ? (
          <div className={styles.searchBarArea}>
            <SearchBar
              onSearch={(value) => onSearch(value, searchByField)}
              inputPlaceholder={searchTitle}
              autoTypeOfInputSearch={autoTypeOfInputSearch}
              inputRef={inputRef}
            />
          </div>
        ) : null}
        {filter ? (
          <div className={styles.filterSection}>
            {sorting}
            {filter}
          </div>
        ) : null}
      </div>
      <div>{headers}</div>
    </div>
  );
};

export default PageHeader;
