import { useMemo } from "react";
import { useAppSelector } from "redux/store/hooks";

export const useUsername = () => {
  const user = useAppSelector((state) => state.oidc.user);

  return useMemo(
    () =>
      [user?.profile.firstName, user?.profile.lastName]
        .filter(Boolean)
        .join(" "),
    [user]
  );
};
