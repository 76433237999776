import { useRef, useLayoutEffect, useState } from "react";
import { Icon, Placeholder, Table } from "semantic-ui-react";
import cx from "classnames";

import useContainerScroll from "hooks/useContainerScroll";
import { CarriersFilter } from "hooks/useCarriersFilter";
import useGetCarriersInfinite from "hooks/useGetCarriersInfinite";
import { SelectedCarrier } from "models/SelectedCarrier";
import CarrierListRow from "./CarrierListRow";

import styles from "../CarrierList.module.scss";

export type CarrierListProps = {
  onSelectCarrier: (carrier: SelectedCarrier) => void;
  selectedCarrier: SelectedCarrier | null;
  carriersFilter: CarriersFilter;
  cancelRequest?: boolean;
};

const CarrierList = (props: CarrierListProps) => {
  const [width, setWidth] = useState<number>(1280);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedCarrierRef = useRef<HTMLTableRowElement>(null);

  const { carriers, totalCarriers, isLoading, getNextPage, reloadCarriers } =
    useGetCarriersInfinite({
      carriersFilter: props.carriersFilter,
      cancelRequest: props.cancelRequest,
    });

  useContainerScroll({
    containerRef,
    canScroll: !isLoading && carriers.length < totalCarriers,
    scrollCallback: getNextPage,
  });

  useLayoutEffect(() => {
    const containerDiv = containerRef.current;
    const selectedCarrierRow = selectedCarrierRef.current;

    if (containerDiv && selectedCarrierRow) {
      setTimeout(() => {
        // Set container scroll to selected carrier row below header
        // scrollTo doesn't exist in testing environment
        containerDiv.scrollTo?.({ top: selectedCarrierRow.offsetTop - 50 });
      }, 0); // Allow repaint of change between large/small or small/large view to happen first
    }
  }, [containerRef, selectedCarrierRef]);

  useLayoutEffect(() => {
    const containerDiv = containerRef.current;

    if (!containerDiv) {
      return;
    }

    const handleResize = () => setWidth(containerDiv.offsetWidth);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(containerDiv);

    return () => resizeObserver.unobserve(containerDiv);
  }, [containerRef]);

  const isFullSize = width > 1250;

  const tableHeaders: { text: string; title?: string }[] = isFullSize
    ? [
        { text: "" },
        { text: "Status" },
        { text: "Carrier" },
        { text: "MC#" },
        { text: "DOT#" },
        { text: "Classification" },
        { text: "Phone" },
        { text: "Email" },
        { text: "City/State" },
        { text: "Last Activity" },
        { text: "Note" },
        { text: "Marketplace" },
      ]
    : [
        { text: "" },
        { text: "Carrier" },
        { text: "Contact" },
        { text: "Last Activity" },
      ];

  const columnCount = tableHeaders.length;

  const renderSpecialRow = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <Table.Row>
          {[...Array(columnCount).keys()].map((value) => (
            <Table.Cell
              key={`${value}-loading-row`}
              className={styles.loadingRow}
            >
              <Placeholder fluid>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Table.Cell>
          ))}
        </Table.Row>
      );
    } else if (carriers.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={columnCount} className={styles.informationCell}>
            <Icon name="warning circle" />
            No Carriers Found
          </Table.Cell>
        </Table.Row>
      );
    } else if (carriers.length >= totalCarriers) {
      return (
        <Table.Row>
          <Table.Cell colSpan={columnCount} className={styles.informationCell}>
            <Icon name="warning circle" />
            End of results
          </Table.Cell>
        </Table.Row>
      );
    } else {
      return null;
    }
  };

  const carrierText =
    totalCarriers === 0 || totalCarriers > 1
      ? "Carriers Found"
      : "Carrier Found";

  return (
    <div
      ref={containerRef}
      className={cx(
        styles.carrierListTableContainer,
        isLoading && styles.loading,
        isFullSize
          ? styles.carrierListTableContainerLarge
          : styles.carrierListTableContainerSmall
      )}
    >
      <Table compact singleLine unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              colSpan={columnCount}
              className={styles.carrierCount}
            >
              {!isLoading
                ? `${totalCarriers.toString()} ${carrierText}`
                : "Loading..."}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {tableHeaders.map(({ text, title }) => (
              <Table.HeaderCell key={text} title={title}>
                {text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {carriers.map((carrier) => (
            <CarrierListRow
              key={carrier.id}
              ref={selectedCarrierRef}
              carrier={carrier}
              isFullSize={isFullSize}
              selectedCarrier={props.selectedCarrier}
              onRowClick={() => {
                props.onSelectCarrier({ id: carrier.id, name: carrier.name });
              }}
              reloadCarriers={reloadCarriers}
            />
          ))}

          {renderSpecialRow()}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CarrierList;
