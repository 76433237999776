import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Icon,
  SemanticCOLORS,
} from "semantic-ui-react";

import {
  carrierClassifications,
  CarrierClassification,
} from "models/dto/CarrierClassification";
import { formatClassification } from "components/CarrierList/List/utils/carrierListUtilities";
import { useGetUserOptions } from "hooks/useGetUserOptions";
import {
  CarriersPopupFilter,
  CarrierStatus,
  carrierStatuses,
  getDefaultCarriersPopupFilter,
  CarriersFilter,
} from "hooks/useCarriersFilter";
import { CarriersOptions } from "hooks/useGetCarriersOptions";
import { useGetHighwayLocationOptions } from "hooks/useGetHighwayLocationOptions";
import { useUsername } from "hooks/useUsername";

import styles from "./CarrierFilterForm.module.scss";

export type CarrierFilterFormProps = {
  carriersFilter: CarriersFilter;
  setCarriersFilter: Dispatch<SetStateAction<CarriersFilter>>;
  closePopup: () => void;
  filterFormOptions: CarriersOptions;
  filterFormOptionsLoading: boolean;
};

export const statusToColorMap: Record<CarrierStatus, SemanticCOLORS> = {
  Active: "blue",
  Created: "green",
  Inactive: "grey",
};

export const defaultAccountOwners = {
  user: "Me",
  none: "No Owner",
};

const CarrierFilterForm = (props: CarrierFilterFormProps) => {
  const username = useUsername();

  const { userOptions, userOptionsLoading, debouncedUserSearch } =
    useGetUserOptions(
      props.carriersFilter.accountOwners
        ? props.carriersFilter.accountOwners.map((accountOwner) => ({
            id: accountOwner.key,
            name: accountOwner.text,
          }))
        : []
    );
  const { locationOptions, locationOptionsLoading, debouncedLocationSearch } =
    useGetHighwayLocationOptions(props.carriersFilter.domicileCities);

  const [popupFilter, setPopupFilter] = useState<CarriersPopupFilter>({
    myCarriers: props.carriersFilter.myCarriers,
    myFavoriteCarriers: props.carriersFilter.myFavoriteCarriers,
    unownedCarriers: props.carriersFilter.unownedCarriers,
    statuses: props.carriersFilter.statuses,
    domicileStates: props.carriersFilter.domicileStates,
    domicileCities: props.carriersFilter.domicileCities,
    accountOwners: props.carriersFilter.accountOwners,
    classifications: props.carriersFilter.classifications,
    equipmentTypes: props.carriersFilter.equipmentTypes,
    trailerTypes: props.carriersFilter.trailerTypes,
    preferredServiceAreas: props.carriersFilter.preferredServiceAreas,
    attributes: props.carriersFilter.attributes,
  });

  const applyCarriersFilter = () => {
    props.setCarriersFilter({ ...props.carriersFilter, ...popupFilter });
    props.closePopup();
  };

  const onChangePopupFilter = useCallback(
    (partialFilter: Partial<CarriersPopupFilter>) => {
      setPopupFilter((currentFilter) => ({
        ...currentFilter,
        ...partialFilter,
      }));
    },
    [setPopupFilter]
  );

  const defaultUserOptions = useMemo(() => {
    return [
      {
        key: "df81ec4d-7d14-4fb5-811d-731097af1ba2",
        value: defaultAccountOwners.user,
        text: username
          ? `${defaultAccountOwners.user} (${username})`
          : defaultAccountOwners.user,
        icon: "user circle",
      },
      {
        key: "bae4365e-72e7-4b07-8b94-045e2ac3167f",
        value: defaultAccountOwners.none,
        text: defaultAccountOwners.none,
        icon: "ban",
      },
    ];
  }, [username]);

  const accountOwnersValue = useMemo(() => {
    const accountOwners: string[] = [];

    if (popupFilter.myCarriers) {
      accountOwners.push(defaultAccountOwners.user);
    }

    if (popupFilter.unownedCarriers) {
      accountOwners.push(defaultAccountOwners.none);
    }

    accountOwners.push(...popupFilter.accountOwners.map((owner) => owner.key));

    return accountOwners;
  }, [
    popupFilter.myCarriers,
    popupFilter.unownedCarriers,
    popupFilter.accountOwners,
  ]);

  return (
    <Form onSubmit={applyCarriersFilter}>
      <div className={styles.popupContainer}>
        <div className={styles.filterHeader}>
          <h2>Carrier Filter</h2>
          <Icon link size="large" name="close" onClick={props.closePopup} />
        </div>

        <div className={styles.scrollContainer}>
          <div>
            <div className={styles.header}>Quick Filters</div>
            <Checkbox
              id="myFavoriteCarriers"
              name="myFavoriteCarriers"
              label="My Favorite Carriers"
              checked={popupFilter.myFavoriteCarriers}
              onChange={(_, { checked }) => {
                onChangePopupFilter({ myFavoriteCarriers: checked });
              }}
            />
          </div>

          <div>
            <div className={styles.header}>Carrier Details</div>
            <div className={styles.inlineDropdowns}>
              <Form.Field inline>
                <label htmlFor="statuses">Carrier Status</label>
                <Dropdown
                  clearable
                  selection
                  multiple
                  id="statuses"
                  name="statuses"
                  aria-label="Carrier Status"
                  placeholder="Carrier Status"
                  options={carrierStatuses.map((status) => ({
                    key: status,
                    text: status,
                    value: status,
                    label: {
                      color: statusToColorMap[status] ?? "grey",
                      empty: true,
                      circular: true,
                    },
                  }))}
                  value={popupFilter.statuses}
                  onChange={(_, { value }) => {
                    onChangePopupFilter({ statuses: value as CarrierStatus[] });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="domicileStates">Domicile State</label>
                <Dropdown
                  clearable
                  selection
                  search
                  multiple
                  id="domicileStates"
                  name="domicileStates"
                  aria-label="Domicile State"
                  placeholder="Domicile State"
                  disabled={popupFilter.domicileCities.length > 0}
                  options={[
                    ...props.filterFormOptions.usStateOptions.map(
                      (stateOption) => ({
                        ...stateOption,
                        flag: "us",
                      })
                    ),
                    ...props.filterFormOptions.caProvinceOptions.map(
                      (provinceOption) => ({
                        ...provinceOption,
                        flag: "ca",
                      })
                    ),
                    ...props.filterFormOptions.mxStateOptions.map(
                      (stateOption) => ({
                        ...stateOption,
                        flag: "mx",
                      })
                    ),
                  ]}
                  loading={props.filterFormOptionsLoading}
                  value={popupFilter.domicileStates}
                  onChange={(_, { value }) => {
                    onChangePopupFilter({ domicileStates: value as string[] });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="domicileCities">Domicile City</label>
                <Dropdown
                  clearable
                  selection
                  search
                  multiple
                  id="domicileCities"
                  name="domicileCities"
                  aria-label="Domicile City"
                  placeholder="Domicile City"
                  options={locationOptions}
                  loading={locationOptionsLoading}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedLocationSearch(searchQuery.trim())
                  }
                  value={popupFilter.domicileCities}
                  onChange={(_, { value }) => {
                    onChangePopupFilter({
                      domicileCities: value as string[],
                      domicileStates: [],
                    });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="accountOwner">Account Owner</label>
                <Dropdown
                  clearable
                  selection
                  search
                  multiple
                  id="accountOwner"
                  name="accountOwner"
                  aria-label="Account Owner"
                  placeholder="Account Owner"
                  options={[...defaultUserOptions, ...userOptions]}
                  loading={userOptionsLoading}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedUserSearch(searchQuery.trim())
                  }
                  value={accountOwnersValue}
                  onChange={(_, { value }) => {
                    const ownersValue = value as string[];
                    const accountOwners = userOptions.filter((userOption) =>
                      ownersValue.includes(userOption.key)
                    );

                    onChangePopupFilter({
                      myCarriers: ownersValue.includes(
                        defaultAccountOwners.user
                      ),
                      unownedCarriers: ownersValue.includes(
                        defaultAccountOwners.none
                      ),
                      accountOwners,
                    });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="classifications">Classification</label>
                <Dropdown
                  clearable
                  selection
                  multiple
                  id="classifications"
                  name="classifications"
                  aria-label="Classification"
                  placeholder="Classification"
                  options={carrierClassifications.map((classifications) => ({
                    key: classifications,
                    text: formatClassification(classifications),
                    value: classifications,
                  }))}
                  value={popupFilter.classifications}
                  onChange={(_, { value }) => {
                    onChangePopupFilter({
                      classifications: value as CarrierClassification[],
                    });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="equipmentTypes">Equipment</label>
                <Dropdown
                  clearable
                  selection
                  search
                  multiple
                  id="equipmentTypes"
                  name="equipmentTypes"
                  aria-label="Equipment"
                  placeholder="Equipment"
                  options={props.filterFormOptions.equipmentOptions}
                  loading={props.filterFormOptionsLoading}
                  value={popupFilter.equipmentTypes.map(
                    (equipmentType) => equipmentType.key
                  )}
                  onChange={(_, { value }) => {
                    const equipmentTypes =
                      props.filterFormOptions.equipmentOptions.filter(
                        (equipmentOption) =>
                          (value as string[]).includes(equipmentOption.key)
                      );

                    onChangePopupFilter({ equipmentTypes });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="trailerTypeIds">Trailer Type</label>
                <Dropdown
                  clearable
                  selection
                  search
                  multiple
                  id="trailerTypeIds"
                  name="trailerTypeIds"
                  aria-label="Trailer Type"
                  placeholder="Trailer Type"
                  options={props.filterFormOptions.trailerOptions}
                  loading={props.filterFormOptionsLoading}
                  value={popupFilter.trailerTypes.map(
                    (trailerType) => trailerType.key
                  )}
                  onChange={(_, { value }) => {
                    const trailerTypes =
                      props.filterFormOptions.trailerOptions.filter(
                        (trailerOption) =>
                          (value as number[]).includes(trailerOption.key)
                      );

                    onChangePopupFilter({ trailerTypes });
                  }}
                />
              </Form.Field>

              <Form.Field inline>
                <label htmlFor="preferredServiceAreas">Preferred Area</label>
                <Dropdown
                  clearable
                  selection
                  multiple
                  search
                  upward
                  id="preferredServiceAreas"
                  name="preferredServiceAreas"
                  aria-label="Preferred Area"
                  placeholder="Preferred Service Area"
                  options={[
                    ...props.filterFormOptions.usStateOptions.map(
                      (stateOption) => ({
                        ...stateOption,
                        flag: "us",
                      })
                    ),
                    ...props.filterFormOptions.caProvinceOptions.map(
                      (provinceOption) => ({
                        ...provinceOption,
                        flag: "ca",
                      })
                    ),
                    ...props.filterFormOptions.mxStateOptions.map(
                      (stateOption) => ({
                        ...stateOption,
                        flag: "mx",
                      })
                    ),
                  ]}
                  loading={props.filterFormOptionsLoading}
                  value={popupFilter.preferredServiceAreas}
                  onChange={(_, { value }) => {
                    onChangePopupFilter({
                      preferredServiceAreas: value as string[],
                    });
                  }}
                />
              </Form.Field>
            </div>
          </div>

          <div>
            <div className={styles.header}>Carrier Attributes</div>

            <div>
              <Form.Field>
                <Dropdown
                  clearable
                  selection
                  multiple
                  search
                  upward
                  id="attributes"
                  name="attributes"
                  aria-label="Attributes"
                  placeholder="Attributes"
                  options={props.filterFormOptions.attributesOptions}
                  loading={props.filterFormOptionsLoading}
                  value={popupFilter.attributes.map(
                    (attribute) => attribute.key
                  )}
                  onChange={(_, { value }) => {
                    const attributes =
                      props.filterFormOptions.attributesOptions.filter(
                        (attributesOption) =>
                          (value as string[]).includes(attributesOption.key)
                      );

                    onChangePopupFilter({ attributes });
                  }}
                />
              </Form.Field>
            </div>
          </div>
        </div>

        <div className={styles.filterFooter}>
          <Button
            type="button"
            onClick={() => setPopupFilter(getDefaultCarriersPopupFilter())}
          >
            <Icon name="redo" />
            Clear Filter
          </Button>

          <Button type="submit" color="blue">
            <Icon name="search" />
            Apply Filter
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default CarrierFilterForm;
