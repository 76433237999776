import * as actionTypes from "../constants/actionTypes";

import { updateObject } from "shared/utils/utility";

const initialState = {
  carrierDetailsLoading: false,

  carrier: {},

  eldProviders: null,
  eldProvidersLoading: false,

  carrierStatuses: [],
  carrierStatusesLoading: false,

  shipmentDetails: {},
  shipmentDetailsLoading: false,

  imageLoading: false,
};

const fetchSingleCarrierStart = (state) =>
  updateObject(state, { carrierDetailsLoading: true });
const fetchSingleCarrierSuccess = (state, action) => ({
  ...state,
  carrierDetailsLoading: false,
  carrier: action.carrier,
});
const fetchSingleCarrierError = (state) =>
  updateObject(state, { carrierDetailsLoading: false });

const validateCarrierStart = (state) => state;
const validateCarrierSuccess = (state) => state;
const validateCarrierError = (state) => state;

const createCarrierStart = (state) => state;
const createCarrierSuccess = (state) => state;
const createCarrierError = (state) => state;

const updateCarrierStart = (state) => state;
const updateCarrierSuccess = (state) => state;
const updateCarrierError = (state) => state;

const fetchShipmentDetailsStart = (state) =>
  updateObject(state, { shipmentDetailsLoading: true });
const fetchShipmentDetailsSuccess = (state, action) => {
  return updateObject(state, {
    shipmentDetailsLoading: false,
    shipmentDetails: action.shipmentDetails,
  });
};
const fetchShipmentDetailsError = (state) =>
  updateObject(state, { shipmentDetailsLoading: false });

const uploadCarriersFromCsvStart = (state) => state;
const uploadCarriersFromCsvSuccess = (state) => state;
const uploadCarriersFromCsvError = (state) => state;

const uploadCarrierImageStart = (state) =>
  updateObject(state, { imageLoading: true });
const uploadCarrierImageSuccess = (state) =>
  updateObject(state, { imageLoading: false });
const uploadCarrierImageError = (state) =>
  updateObject(state, { imageLoading: false });

const deleteCarrierImageStart = (state) =>
  updateObject(state, { imageLoading: true });
const deleteCarrierImageSuccess = (state) =>
  updateObject(state, { imageLoading: false });
const deleteCarrierImageError = (state) =>
  updateObject(state, { imageLoading: false });

const updateCarrierNotes = (state, action) => {
  return updateObject(state, {
    carriers: {
      entities: state.carriers.entities.map((element) => {
        if (element.id === action.carrier.id) {
          return {
            ...element,
            lastActivity: { ...action.carrier.lastActivity },
          };
        } else {
          return element;
        }
      }),
    },
  });
};

export const setNotifications = (state, action) => {
  const carriersFromState = state.carriers?.entities;
  const updatedCarriers = carriersFromState.map((el) => {
    const notification = action.payload.find(
      (n) => n.profilesCarrierId === el.id
    );
    if (notification) {
      return {
        ...el,
        unseenNotificationsCount: notification.unseenNotificationsCount,
      };
    }

    return el;
  });

  return updateObject(state, {
    carriers: {
      entities: updatedCarriers,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SINGLE_CARRIER_START:
      return fetchSingleCarrierStart(state);
    case actionTypes.FETCH_SINGLE_CARRIER_SUCCESS:
      return fetchSingleCarrierSuccess(state, action);
    case actionTypes.FETCH_SINGLE_CARRIER_ERROR:
      return fetchSingleCarrierError(state);

    case actionTypes.FETCH_CARRIER_NOTES_SUCCESS:
      return updateCarrierNotes(state, action);

    case actionTypes.VALIDATE_CARRIER_START:
      return validateCarrierStart(state);
    case actionTypes.VALIDATE_CARRIER_SUCCESS:
      return validateCarrierSuccess(state);
    case actionTypes.VALIDATE_CARRIER_ERROR:
      return validateCarrierError(state);

    case actionTypes.CREATE_CARRIER_START:
      return createCarrierStart(state);
    case actionTypes.CREATE_CARRIER_SUCCESS:
      return createCarrierSuccess(state);
    case actionTypes.CREATE_CARRIER_ERROR:
      return createCarrierError(state);

    case actionTypes.UPDATE_CARRIER_START:
      return updateCarrierStart(state);
    case actionTypes.UPDATE_CARRIER_SUCCESS:
      return updateCarrierSuccess(state);
    case actionTypes.UPDATE_CARRIER_ERROR:
      return updateCarrierError(state);

    case actionTypes.FETCH_SHIPMENT_DETAILS_START:
      return fetchShipmentDetailsStart(state);
    case actionTypes.FETCH_SHIPMENT_DETAILS_SUCCESS:
      return fetchShipmentDetailsSuccess(state, action);
    case actionTypes.FETCH_SHIPMENT_DETAILS_ERROR:
      return fetchShipmentDetailsError(state);

    case actionTypes.UPLOAD_FROM_CSV_START:
      return uploadCarriersFromCsvStart(state);
    case actionTypes.UPLOAD_FROM_CSV_SUCCESS:
      return uploadCarriersFromCsvSuccess(state);
    case actionTypes.UPLOAD_FROM_CSV_ERROR:
      return uploadCarriersFromCsvError(state);

    case actionTypes.UPLOAD_IMAGE_START:
      return uploadCarrierImageStart(state);
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      return uploadCarrierImageSuccess(state);
    case actionTypes.UPLOAD_IMAGE_ERROR:
      return uploadCarrierImageError(state);

    case actionTypes.DELETE_IMAGE_START:
      return deleteCarrierImageStart(state);
    case actionTypes.DELETE_IMAGE_SUCCESS:
      return deleteCarrierImageSuccess(state);
    case actionTypes.DELETE_IMAGE_ERROR:
      return deleteCarrierImageError(state);

    default:
      return state;
  }
};

export default reducer;
