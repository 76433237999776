import axios from "axios";
import { useMemo, useState } from "react";
import { debounce } from "lodash";

import { Option } from "models/Option";
import { inputDebounceDelay } from "./constants";
import { getUniqueOptions } from "utils/getUniqueOptions";

export const useGetHighwayLocationOptions = (initialLocations: string[]) => {
  const [locationOptions, setLocationOptions] = useState<Option<string>[]>(() =>
    getUniqueOptions(
      initialLocations.reduce<Option<string>[]>((acc, location) => {
        if (location) {
          acc.push({ key: location, value: location, text: location });
        }
        return acc;
      }, [])
    )
  );
  const [locationOptionsLoading, setLocationOptionsLoading] = useState(false);

  const debouncedLocationSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        setLocationOptionsLoading(true);
        const locationsList = await getLocationsList(search);
        setLocationOptions((currentLocationOptions) =>
          getUniqueOptions([
            ...currentLocationOptions,
            ...mapLocationsToOptions(locationsList),
          ])
        );
        setLocationOptionsLoading(false);
      }, inputDebounceDelay),
    []
  );

  return { locationOptions, locationOptionsLoading, debouncedLocationSearch };
};

export const getLocationsList = (search: string): Promise<string[]> =>
  axios
    .get<string[]>("/api/locations/highwayCities", {
      params: { search },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

export const mapLocationsToOptions = (data: string[]): Option<string>[] =>
  data.map((location) => ({
    key: location,
    value: location,
    text: location,
  }));
