import axios from "axios";
import { useMemo, useState } from "react";
import { debounce } from "lodash";

import { Option } from "models/Option";
import { inputDebounceDelay } from "./constants";
import { QueryGridResponse } from "models/QueryGridResponse";
import { TurvoUserDto } from "models/dto/TurvoUserDto";
import { getUniqueOptions } from "utils/getUniqueOptions";

export const useGetUserOptions = (
  initialUsers: { id: string; name: string }[]
) => {
  const [userOptions, setUserOptions] = useState<Option<string>[]>(() =>
    getUniqueOptions(
      initialUsers.reduce<Option<string>[]>((acc, user) => {
        if (user) {
          acc.push({ key: user.id, value: user.id, text: user.name });
        }
        return acc;
      }, [])
    )
  );
  const [userOptionsLoading, setUserOptionsLoading] = useState(false);

  const debouncedUserSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        setUserOptionsLoading(true);
        const usersList = await getUsersList(search);
        setUserOptions((currentUserOptions) =>
          getUniqueOptions([
            ...currentUserOptions,
            ...mapUsersToOptions(usersList),
          ])
        );
        setUserOptionsLoading(false);
      }, inputDebounceDelay),
    []
  );

  return { userOptions, userOptionsLoading, debouncedUserSearch };
};

export const getUsersList = (
  name: string
): Promise<QueryGridResponse<TurvoUserDto>> =>
  axios
    .get<QueryGridResponse<TurvoUserDto>>("/api/users/turvo/list", {
      params: {
        name,
        page: 1,
        pageSize: 10,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return { filteredCount: 0, entities: [] };
    });

export const mapUsersToOptions = (
  data: QueryGridResponse<TurvoUserDto>
): Option<string>[] =>
  data.entities.reduce<Option<string>[]>((acc, user) => {
    if (user.id && user.name) {
      acc.push({
        key: user.id,
        value: user.id,
        text: user.name,
      });
    }
    return acc;
  }, []);
