import { Icon } from "semantic-ui-react";
import CarrierCapacity from "components/CarrierCapacity/CarrierCapacity";
import CarrierList from "components/CarrierList/List/CarrierList";
import { SelectedCarrier } from "models/SelectedCarrier";
import { CarriersFilter } from "hooks/useCarriersFilter";

import styles from "pages/Carriers/Carriers.module.scss";

export type CarrierQuickCapacityProps = {
  onSelectCarrier: (carrier: SelectedCarrier) => void;
  onQuickCapacityClose: () => void;
  resetSelectedCarrier: () => void;
  selectedCarrier: SelectedCarrier;
  carriersFilter: CarriersFilter;
  cancelRequest?: boolean;
};

export const CarrierQuickCapacity = (props: CarrierQuickCapacityProps) => {
  return (
    <div className={styles.carrierMinifiedListContainer}>
      <div className={styles.listArea}>
        <CarrierList {...props} />
      </div>

      <div className={styles.capacityArea}>
        <div className={styles.capacityAreaHeader}>
          <h1>{props.selectedCarrier.name}</h1>

          <Icon
            link
            name="close"
            size="large"
            onClick={props.onQuickCapacityClose}
          />
        </div>

        <CarrierCapacity carrier={props.selectedCarrier} />
      </div>
    </div>
  );
};
