import { User } from "oidc-client";
import { useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useAppSelector } from "redux/store/hooks";
import { RootState } from "redux/store/store";
import { CarrierClassification } from "models/dto/CarrierClassification";
import { QueryGridSortItem } from "models/dto/QueryGridSortItem";
import { Option } from "models/Option";

export const carrierStatuses = ["Active", "Created", "Inactive"] as const;

export type CarrierStatus = (typeof carrierStatuses)[number];

export type CarriersPopupFilter = {
  myCarriers: boolean;
  unownedCarriers: boolean;
  myFavoriteCarriers: boolean;
  statuses: CarrierStatus[];
  domicileStates: string[];
  domicileCities: string[];
  accountOwners: Option<string>[];
  classifications: CarrierClassification[];
  equipmentTypes: Option<string>[];
  trailerTypes: Option<number>[];
  preferredServiceAreas: string[];
  attributes: Option<string>[];
};

export type CarriersFilter = CarriersPopupFilter &
  QueryGridSortItem & {
    search: string;
  };

export const getDefaultCarriersPopupFilter = (): CarriersPopupFilter => ({
  myCarriers: false,
  myFavoriteCarriers: false,
  unownedCarriers: false,
  statuses: [],
  domicileStates: [],
  domicileCities: [],
  accountOwners: [],
  classifications: [],
  equipmentTypes: [],
  trailerTypes: [],
  preferredServiceAreas: [],
  attributes: [],
});

export const getDefaultCarriersFilter = (): CarriersFilter => ({
  sortField: "Name",
  dir: "ASC",
  search: "",
  ...getDefaultCarriersPopupFilter(),
});

const getLocalStorageKey = (user?: User) => {
  const firstName = user?.profile?.firstName || "";
  const lastName = user?.profile?.lastName || "";

  return `${firstName}_${lastName}_profiles-filter`;
};

const useCarriersFilter = (): [
  CarriersFilter,
  Dispatch<SetStateAction<CarriersFilter>>,
] => {
  const location = useLocation();
  const user = useAppSelector((state: RootState) => state.oidc.user);

  const [carriersFilter, setCarriersFilter] = useState<CarriersFilter>(() => {
    const defaultFilter = getDefaultCarriersFilter();

    const storedData = localStorage.getItem(getLocalStorageKey(user));

    let initialFilter: CarriersFilter = storedData
      ? JSON.parse(storedData)
      : defaultFilter;

    // Check if the stored filter is valid
    Object.keys(initialFilter).forEach((key) => {
      if (defaultFilter[key as keyof CarriersFilter] === undefined) {
        initialFilter = defaultFilter;
      }
    });

    // Always reset searchFilter on page load
    initialFilter.search = defaultFilter.search;

    return initialFilter;
  });

  // Save all filter changes to local storage
  useEffect(() => {
    const filterString = JSON.stringify(carriersFilter);

    if (user) {
      localStorage.setItem(getLocalStorageKey(user), filterString);
    }
  }, [carriersFilter, user]);

  // If redirected from edit carrier page search, then initiate a search with that query
  // Also replace the state with an empty object to prevent the search from being triggered again on refresh
  const { state, pathname } = location;
  useEffect(() => {
    if (state?.searchText) {
      setCarriersFilter((previousFilter) => ({
        ...previousFilter,
        search: state.searchText,
      }));

      window.history.replaceState({}, "", pathname);
    }
  }, [state, pathname]);

  return [carriersFilter, setCarriersFilter];
};

export default useCarriersFilter;
