import { PropsWithChildren } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

import styles from "./CarrierFilterBar.module.scss";

export type CarrierFilterLabelPopupProps = {
  labelText: string;
  title: string;
  resetField: () => void;
};

const CarrierFilterLabelPopup = (
  props: PropsWithChildren<CarrierFilterLabelPopupProps>
) => (
  <Popup
    flowing
    position="bottom left"
    trigger={
      <Label color="blue" className={styles.labelTag}>
        {props.labelText}
        <Icon name="delete" title={props.title} onClick={props.resetField} />
      </Label>
    }
  >
    <Popup.Content>{props.children}</Popup.Content>
  </Popup>
);

export default CarrierFilterLabelPopup;
