import { useState, useMemo } from "react";
import debounce from "lodash/debounce";

import { SelectedCarrier } from "models/SelectedCarrier";
import { CarrierQuickCapacity } from "components/CarrierQuickCapacity/CarrierQuickCapacity";
import CarrierList from "components/CarrierList/List/CarrierList";
import Breadcrumbs from "components/Navigation/Breadcrumbs/Breadcrumbs";
import { SearchBar } from "components/Navigation/SearchBar";
import CarriersFilterBar from "./CarrierFilterBar/CarrierFilterBar";
import { inputDebounceDelay } from "hooks/constants";
import useCarriersFilter from "hooks/useCarriersFilter";

import styles from "./Carriers.module.scss";

const Carriers = () => {
  const [isQuickCapacityOpen, setIsQuickCapacityOpen] =
    useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] =
    useState<SelectedCarrier | null>(null);
  const [cancelRequest, setCancelRequest] = useState<boolean>(false);

  const [carriersFilter, setCarriersFilter] = useCarriersFilter();

  const onSelectCarrier = (carrier: SelectedCarrier) => {
    setIsQuickCapacityOpen(true);
    setSelectedCarrier(carrier);
  };

  const debouncedSearchChange = useMemo(
    () =>
      debounce((searchText) => {
        setCancelRequest(false);
        setCarriersFilter((currentFilter) => ({
          ...currentFilter,
          search: searchText,
        }));
        setSelectedCarrier(null);
        setIsQuickCapacityOpen(false);
      }, inputDebounceDelay),
    [setCarriersFilter]
  );

  const onSearch = useMemo(
    () => (searchText: string) => {
      // cancelRequest gets passed to useGetCarriersInfinite to cancel in-flight requests as the user continues to type
      // Better UX and avoids user confusion about the results displayed
      setCancelRequest(true);
      debouncedSearchChange(searchText);
    },
    [debouncedSearchChange]
  );

  return (
    <div className={styles.carriersContainer}>
      <div className={styles.carriersSearchBar}>
        <Breadcrumbs />

        <SearchBar
          inputPlaceholder="Search by Name / MC# / DOT#"
          onSearch={onSearch}
          autoTypeOfInputSearch={true}
          value={carriersFilter.search}
          disabled={carriersFilter.search.length === 0}
        />
      </div>

      <CarriersFilterBar
        carriersFilter={carriersFilter}
        setCarriersFilter={setCarriersFilter}
      />

      {isQuickCapacityOpen && selectedCarrier ? (
        <CarrierQuickCapacity
          onSelectCarrier={onSelectCarrier}
          onQuickCapacityClose={() => setIsQuickCapacityOpen(false)}
          resetSelectedCarrier={() => setSelectedCarrier(null)}
          selectedCarrier={selectedCarrier}
          carriersFilter={carriersFilter}
          cancelRequest={cancelRequest}
        />
      ) : (
        <CarrierList
          onSelectCarrier={onSelectCarrier}
          selectedCarrier={selectedCarrier}
          carriersFilter={carriersFilter}
          cancelRequest={cancelRequest}
        />
      )}
    </div>
  );
};

export default Carriers;
