interface Activities {
  key: string;
  text: string;
}

export const capacityNoteText = {
  noCapacityCreate: "does not have capacity",
  noCapacityEdit: "does not have capacity (edit)",
  noPlanCapacityCreate: "does not have plan capacity",
  noPlanCapacityEdit: "does not have plan capacity (edit)",
};

export const capacityNoteTypes: Activities[] = [
  {
    key: capacityNoteText.noCapacityEdit,
    text: "Edited No Capacity",
  },
  {
    key: capacityNoteText.noCapacityCreate,
    text: "Added No Capacity",
  },
  {
    key: capacityNoteText.noPlanCapacityEdit,
    text: "Edited No Plan Capacity",
  },
  {
    key: capacityNoteText.noPlanCapacityCreate,
    text: "Added No Plan Capacity",
  },
];

export const capacityDeleteTypes: Activities[] = [
  { key: "expired", text: "Expired Capacity" },
  { key: "removed", text: "Deleted Capacity" },
];

export const activityTypes: Activities[] = [
  { key: "POST", text: "Added" },
  { key: "PUT", text: "Updated" },
  { key: "DELETE", text: "Deleted" },
  { key: "NOTE_CREATE", text: "Note Added No Cap" },
  { key: "LVM", text: "Note added Left Voicemail" },
];

export const entityTypes: Activities[] = [
  { key: "CARRIER", text: "Carrier" },
  { key: "CAPACITY", text: "Capacity" },
  { key: "KNOWN_LANE", text: "Known Lane" },
  { key: "USER", text: "User" },
  { key: "CUSTOMER", text: "Customer" },
  { key: "ADDRESS", text: "Address" },
  { key: "EMAIL", text: "Email" },
  { key: "PHONE", text: "Phone" },
  { key: "CONTACT", text: "Contact" },
  { key: "CONTACT_ADDRESS", text: "Contact Address" },
  { key: "CONTACT_EMAIL", text: "Contact Email" },
  { key: "CONTACT_PHONE", text: "Contact Phone" },
  { key: "EQUIPMENT", text: "Equipment" },
  { key: "LOCATION", text: "Location" },
  { key: "CARRIER_ATTRIBUTE", text: "Carrier Attribute" },
  { key: "CUSTOMER_ATTRIBUTE", text: "Customer Attribute" },
  { key: "CLASSIFICATION", text: "Classification" },
  { key: "OWNER", text: "Carrier Owner" },
  { key: "ACCOUNT_NOTE", text: "Account Note" },
];
