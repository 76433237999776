import styles from "./searchbar.module.scss";
import { Icon } from "semantic-ui-react";
import {
  ChangeEvent,
  KeyboardEvent,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";

type SearchBarProps = {
  inputPlaceholder: string;
  onSearch: (value: string) => void;
  autoTypeOfInputSearch?: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
  disabled?: boolean;
  value?: string;
};

export const SearchBar = ({
  onSearch,
  inputRef,
  inputPlaceholder,
  autoTypeOfInputSearch = false,
  disabled = false,
  value = "",
}: SearchBarProps) => {
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const searchLoopStyle: string = autoTypeOfInputSearch
    ? styles.icon_wrapper_auto_search
    : styles.icon_wrapper;

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
    },
    []
  );

  const handleSearchButtonClick = useCallback(() => {
    onSearch(inputValue);
  }, [inputValue, onSearch]);

  const handleOnInputChangeAutoSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      onSearch(value);
    },
    [onSearch]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && onSearch) {
        onSearch(inputValue);
      }
    },
    [inputValue, onSearch]
  );

  return (
    <div className={styles.search_wrapper}>
      <input
        ref={inputRef}
        type="text"
        placeholder={inputPlaceholder}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        value={inputValue}
        onChange={
          autoTypeOfInputSearch
            ? handleOnInputChangeAutoSearch
            : handleOnInputChange
        }
        onKeyDown={handleKeyPress}
        autoFocus
      />
      <div
        className={`${searchLoopStyle} ${focusInput ? styles.focus_input : ""}`}
        title="search-area"
      >
        <Icon
          link
          name="search"
          disabled={disabled}
          className={disabled ? styles.default_cursor : ""}
          onClick={handleSearchButtonClick}
          title="search-icon"
        />
      </div>
    </div>
  );
};
