import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "./carrierThunks";

export const carrierSlice = createSlice({
  name: "carrierEdit",
  initialState: {
    carrier: {},
    loading: false,
    summary: {},
    loadingDetails: false,
    optionsData: {},
    loadingOptions: false,
    error: {
      status: false,
      message: "",
    },
  },
  reducers: {
    resetCarrier: (state) => {
      state.carrier = {};
      state.summary = {};
    },

    updateCarrierField: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      state.carrier[fieldName] = fieldValue;
    },

    addCarrierItem(state, action) {
      const { name, item } = action.payload;
      const items = state.carrier[name] || [];
      items.push(item);
      state.carrier[name] = items;
    },

    updateCarrierItem(state, action) {
      const { name, item } = action.payload;
      const items = state.carrier[name];

      if (!items || items.length === 0) {
        console.error(`Carrier has no any ${name} details`);
        return;
      }

      const itemIndex = items.findIndex((el) => el.id === item.id);

      if (itemIndex < 0) {
        console.error(
          `Impossible to update carrier's ${name} details item: there is no element to update`
        );
        return;
      }

      state.carrier[name][itemIndex] = item;
    },

    removeCarrierItem(state, action) {
      const { name, itemId } = action.payload;
      const items = state.carrier[name];
      if (!items || items.length === 0) {
        console.error(`Carrier has no any ${name} details`);
        return;
      }
      state.carrier[name] = items.filter((x) => x.id !== itemId);
    },

    addServiceArea(state, action) {
      const { entityName, item } = action.payload;
      if (state.carrier[entityName]) {
        state.carrier[entityName].push(item);
      } else {
        state.carrier[entityName] = [item];
      }
    },

    removeServiceArea(state, action) {
      const { entityName, item } = action.payload;
      state.carrier[entityName] = state.carrier[entityName].filter(
        (x) => x.id !== item.id
      );
    },
  },
  extraReducers: {
    [thunks.fetchCarrier.pending]: (state) => {
      state.loading = true;
      state.error = {
        status: false,
        message: "",
      };
    },
    [thunks.fetchCarrier.fulfilled]: (state, action) => {
      state.loading = false;
      state.carrier = action.payload;
    },
    [thunks.fetchCarrier.rejected]: (state, action) => {
      const { payload } = action;
      state.error.status = true;
      let errorMessage = "An error occurred";
      if (payload === 404) {
        errorMessage = "Sorry, we are unable to find a carrier with this ID.";
      }
      state.error.message = errorMessage;
      state.loading = false;
      state.loadingDetails = false;
      state.loadingOptions = false;
    },

    [thunks.starCarrier.pending]: (state) => {
      const { isStarredByCurrentUser } = state.carrier;
      state.carrier.isStarredByCurrentUser = !isStarredByCurrentUser;
    },
    [thunks.starCarrier.rejected]: (state) => {
      const { isStarredByCurrentUser } = state.carrier;
      state.carrier.isStarredByCurrentUser = !isStarredByCurrentUser;
    },

    [thunks.fetchCarrierSummary.pending]: (state) => {
      state.loading = true;
    },
    [thunks.fetchCarrierSummary.fulfilled]: (state, action) => {
      state.summary = action.payload;
      state.loading = false;
    },
    [thunks.fetchCarrierSummary.rejected]: (state) => {
      state.loading = false;
    },

    [thunks.saveCarrier.pending]: (state) => {
      state.loading = true;
    },
    [thunks.saveCarrier.fulfilled]: (state) => {
      state.loading = false;
    },
    [thunks.saveCarrier.rejected]: (state) => {
      state.loading = false;
    },

    [thunks.fetchCarrierOptions.pending]: (state) => {
      state.loadingOptions = true;
    },
    [thunks.fetchCarrierOptions.fulfilled]: (state, action) => {
      state.loadingOptions = false;
      state.optionsData = action.payload;
    },
    [thunks.fetchCarrierOptions.rejected]: (state) => {
      state.loadingOptions = false;
    },
  },
});

export const actions = {
  ...carrierSlice.actions,
  ...thunks,
};

export const reducer = carrierSlice.reducer;
