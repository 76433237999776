interface SortOptions {
  key: number;
  value: string;
  text: string;
}

export const carrierSortOptions: SortOptions[] = [
  { key: 1, value: "Name,ASC", text: "▲ Name" },
  { key: 2, value: "Name,DESC", text: "▼ Name" },
  { key: 3, value: "LastActivity,ASC", text: "▲ Last Activity" },
  { key: 4, value: "LastActivity,DESC", text: "▼ Last Activity" },
  { key: 5, value: "TruckQuantity,ASC", text: "▲ Trailer Quantity" },
  { key: 6, value: "TruckQuantity,DESC", text: "▼ Trailer Quantity" },
];

export const userSortOptions: SortOptions[] = [
  { key: 1, value: "firstName", text: "first name: up" },
  { key: 2, value: "firstName,desc", text: "first name: down" },
  { key: 3, value: "lastName", text: "last name: up" },
  { key: 4, value: "lastName,desc", text: "last name: down" },
  { key: 5, value: "lastActivity", text: "last activity: up" },
  { key: 6, value: "lastActivity,desc", text: "last activity: down" },
];

export const customerSortOptions: SortOptions[] = [
  { key: 1, value: "turvoCustomer.name", text: "name: up" },
  { key: 2, value: "turvoCustomer.name,desc", text: "name: down" },
  { key: 3, value: "lastActivity", text: "last activity: up" },
  { key: 4, value: "lastActivity,desc", text: "last activity: down" },
];

export const locationSortOptions: SortOptions[] = [
  { key: 1, value: "name", text: "name: up" },
  { key: 2, value: "name,desc", text: "name: down" },
  { key: 3, value: "lastActivity", text: "last activity: up" },
  { key: 4, value: "lastActivity,desc", text: "last activity: down" },
  { key: 5, value: ",", text: "none" },
];

export const searchFields: SortOptions[] = [
  { key: -1, text: "All", value: "All" },
  { key: 0, text: "Origin Name", value: "OriginName" },
  { key: 1, text: "Origin", value: "Origin" },
  { key: 2, text: "Destination Name", value: "DestinationName" },
  { key: 3, text: "Customer", value: "Customer" },
  { key: 4, text: "Create Date", value: "CreateDate" },
  { key: 5, text: "Expected Date", value: "ExpectedDate" },
];
