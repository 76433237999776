import { TimelineDto } from "models/dto/TimelineDto";
import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";
import { PhoneDto } from "models/dto/PhoneDto";
import { CarrierClassification } from "models/dto/CarrierClassification";

export const defaultValue = "N/A";

export const getValueOrDefault = (value: string | null | undefined) => {
  if (value === null || value === undefined || value === "") {
    return defaultValue;
  } else {
    return value;
  }
};

export const formatContentSummaryForCarrierRow = (activity: TimelineDto) => {
  const summaryEntityType =
    activityConstants.entityTypes.find(
      (type) => type.key === activity.entityType
    )?.text ?? "";

  const summaryAction =
    activityConstants.activityTypes.find(
      (type) => type.key === activity.actionType
    )?.text ?? "";

  const user = activity.userName ? `${activity.userName}: ` : "";

  return `${user} ${summaryAction} ${summaryEntityType}`;
};

export const formatPhoneForCarrierRow = (phone: PhoneDto) =>
  formatPhoneNumberWithExtentionAndCountryCode({
    phoneNumber: phone.phoneNumber ?? "",
    countryCode: phone.countryCode ?? "",
    extension: phone.extension ?? "",
  });

export const formatAddressForCarrierRow = (
  city: string,
  state: string
): string => [city, state].filter(Boolean).join(", ") || defaultValue;

export const formatDateForCarrierRow = (dateString: string) => {
  const parsedDate = new Date(
    dateString.endsWith("Z") ? dateString : dateString + "Z"
  );

  if (isNaN(parsedDate.getTime())) {
    return "N/A";
  }

  return new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Detroit",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(parsedDate);
};

export const formatClassification = (
  classification: CarrierClassification | null
): string => {
  if (!classification) {
    return defaultValue;
  }

  const classificationToTextMap: Record<CarrierClassification, string> = {
    Freight: "Freight",
    Vehicle: "Vehicle",
    FreightAndVehicle: "Freight & Vehicle",
  };

  return classificationToTextMap[classification] ?? classification;
};
